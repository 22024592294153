import React from 'react';
import { Config } from 'types';
import { Email, GitHub, Discord, Twitter } from 'icons';

const config: Config = {
  name: {
    display: 'SLDDL',
    aria: "I'm SLDDL",
  },
  title: {
    display: '',
    aria: '',
  },
  buttons: [
    {
      display: 'GitHub',
      aria: 'Visit my GitHub profile',
      icon: <GitHub />,
      href: 'https://github.com/SLDDL',
    },
    {
      display: 'Discord',
      aria: 'Visit my discord server',
      icon: <Discord />,
      href: 'https://discord.gg/n3s58h2V9h',
    },
    {
      display: 'Twitter',
      aria: 'Visit my Twitter page',
      icon: <Twitter />,
      href: 'https://twitter.com/rerorero_rero_',
    },
    {
      display: 'Email',
      aria: 'Send me an email',
      icon: <Email />,
      href: 'mailto:slddl.hoe@gmail.com',
    },
  ],
};

export default config;
